import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getSrc } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { scroller } from 'react-scroll'

import {
  Layout,
  Seo,
  Container,
  DesktopProductImages,
  ProductDetails,
  MobileProductImages,
  GoBack,
} from '../components'
import isEqual from 'lodash.isequal'
import { useStoreContext } from '../context/storeContext'
import { formatPrice, isUserUsingMobile } from '../utils'
import { breakpoints } from '../styles/globalStyle'

export default function Product({ data: { product } }) {
  // console.log(product)

  const {
    title,
    description,
    images: [firstImage],
    priceRangeV2,
    variants,
    variants: [initialVariant],
  } = product

  const { client }: any = useStoreContext()

  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const [mainImage, setMainImage] = useState(0)

  const productVariant = client?.product?.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = React.useCallback(
    productId => {
      client?.product?.fetch(productId).then(fetchedProduct => {
        const result = fetchedProduct?.variants.filter(variant => variant.id === productVariant.storefrontId) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client?.product]
  )

  const selectOption = (index, event) => {
    const value = event.target.dataset.value

    if (value === '') {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    const selectedImage = product.images.find(
      image =>
        selectedVariant.image?.gatsbyImageData.images.sources[0].srcSet.split('files/').pop().split('_crop')[0] ==
        image?.gatsbyImageData.images.sources[0].srcSet.split('files/').pop().split('_crop')[0]
    )

    const selectedImageIdx = product.images.findIndex(
      image =>
        selectedVariant.image?.gatsbyImageData.images.sources[0].srcSet.split('files/').pop().split('_crop')[0] ==
        image?.gatsbyImageData.images.sources[0].srcSet.split('files/').pop().split('_crop')[0]
    )
    setMainImage(selectedImageIdx)

    scroller.scrollTo(`product-image-${selectedImage.id}`, {
      duration: 300,
      spy: true,
      smooth: true,
      offset: isUserUsingMobile() ? -10000 : -110,
    })

    setVariant({ ...selectedVariant })
  }

  useEffect(() => {
    checkAvailability(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailability, product.storefrontId])

  const price = formatPrice(priceRangeV2.minVariantPrice.currencyCode, variant.price)

  const hasVariants = variants.length > 1

  return (
    <Layout>
      <Seo title={title} description={description} image={getSrc(firstImage.gatsbyImageData)} />
      <SingleProductWrap>
        <MobileProductImages {...product} setMainImage={setMainImage} mainImage={mainImage} /> {/* Mobile Images */}
        <Container>
          <GoBack />
          <SingleProductInner>
            {/* <p>{title}</p> */}
            <ProductDetails
              {...product}
              price={price}
              hasVariants={true}
              variant={variant}
              selectOption={selectOption}
              quantity={quantity}
              setQuantity={setQuantity}
              variantId={productVariant.storefrontId}
              available={available}
            />
            <DesktopProductImages {...product} /> {/* Desktop Images */}
          </SingleProductInner>
        </Container>
      </SingleProductWrap>
    </Layout>
  )
}

const SingleProductWrap = styled.div`
  position: relative;
`

const SingleProductInner = styled.section`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  overflow: visible;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 3rem;
    overflow: unset;
  }
`

export const query = graphql`
  query ($id: String, $productType: String) {
    product: shopifyProduct(id: { eq: $id }) {
      id
      title
      description
      descriptionHtml
      productType
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        # altText
        id
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 0)
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        inventoryQuantity
        selectedOptions {
          name
          value
        }
        image {
          id
          gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 0)
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(limit: 3, filter: { productType: { eq: $productType }, id: { ne: $id } }) {
      nodes {
        ...ProductCard
      }
    }
  }
`
